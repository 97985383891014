/* General base styles
========================================================================== */
@import url("https://use.typekit.net/pju7ias.css");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img, iframe {
  vertical-align: bottom;
  max-width: 100%;
}

input, textarea, select {
  font: inherit;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

/* Document
========================================================================== */
/**
* 1. Correct the line height in all browsers.
* 2. Prevent adjustments of font size after orientation changes in iOS.
*/
html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/* Grouping content
    ========================================================================== */
/**
* 1. Add the correct box sizing in Firefox.
* 2. Show the overflow in Edge and IE.
*/
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/* Text-level semantics
    ========================================================================== */
/**
* Remove the gray background on active links in IE 10.
*/
a {
  background-color: transparent;
}

/**
* 1. Remove the bottom border in Chrome 57-
* 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
*/
abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

/**
* Add the correct font weight in Chrome, Edge, and Safari.
*/
b,
strong {
  font-weight: bolder;
}

/**
* 1. Correct the inheritance and scaling of font size in all browsers.
* 2. Correct the odd `em` font sizing in all browsers.
*/
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
* Add the correct font size in all browsers.
*/
small {
  font-size: 80%;
}

/**
* Prevent `sub` and `sup` elements from affecting the line height in
* all browsers.
*/
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
    ========================================================================== */
/**
* Remove the border on images inside links in IE 10.
*/
img {
  border-style: none;
}

/* Forms
    ========================================================================== */
/**
* 1. Change the font styles in all browsers.
* 2. Remove the margin in Firefox and Safari.
*/
button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
* Show the overflow in IE.
* 1. Show the overflow in Edge.
*/
button,
input { /* 1 */
  overflow: visible;
}

/**
* Remove the inheritance of text transform in Edge, Firefox, and IE.
* 1. Remove the inheritance of text transform in Firefox.
*/
button,
select { /* 1 */
  text-transform: none;
}

/**
* Correct the inability to style clickable types in iOS and Safari.
*/
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

/**
* Remove the inner border and padding in Firefox.
*/
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
* Restore the focus styles unset by the previous rule.
*/
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
* Correct the padding in Firefox.
*/
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
* 1. Correct the text wrapping in Edge and IE.
* 2. Correct the color inheritance from `fieldset` elements in IE.
* 3. Remove the padding so developers are not caught out when they zero out
*    `fieldset` elements in all browsers.
*/
legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

/**
* Add the correct vertical alignment in Chrome, Firefox, and Opera.
*/
progress {
  vertical-align: baseline;
}

/**
* Remove the default vertical scrollbar in IE 10+.
*/
textarea {
  overflow: auto;
}

/**
* 1. Add the correct box sizing in IE 10.
* 2. Remove the padding in IE 10.
*/
[type=checkbox],
[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
* Correct the cursor style of increment and decrement buttons in Chrome.
*/
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
* 1. Correct the odd appearance in Chrome and Safari.
* 2. Correct the outline style in Safari.
*/
[type=search] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
* Remove the inner padding in Chrome and Safari on macOS.
*/
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
* 1. Correct the inability to style clickable types in iOS and Safari.
* 2. Change font properties to `inherit` in Safari.
*/
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/* Interactive
    ========================================================================== */
/*
* Add the correct display in Edge, IE 10+, and Firefox.
*/
details {
  display: block;
}

/*
* Add the correct display in all browsers.
*/
summary {
  display: list-item;
}

/* Misc
    ========================================================================== */
/**
* Add the correct display in IE 10+.
*/
template {
  display: none;
}

/**
* Add the correct display in IE 10.
*/
[hidden] {
  display: none;
}

/* Universal Styles
========================================================================== */
/* Utility Styles
----------------------- */
html {
  scroll-behavior: smooth;
}

img {
  object-fit: cover;
  object-position: center;
  aspect-ratio: 4/5;
}

/* Typography
----------------------- */
body {
  font-size: 20px;
  line-height: 150%;
  font-family: "minion-pro", serif;
  font-weight: 400;
  font-style: normal;
  color: #000000;
}

footer {
  font-size: 14px;
  font-family: "din-2014", serif;
  line-height: 100%;
}

a {
  color: inherit;
  text-decoration: underline;
  display: inline-block;
  position: relative;
  z-index: 1;
}
a::after {
  content: "";
  background: #A28787;
  width: 0;
  height: 100%;
  position: absolute;
  z-index: -1;
  bottom: 1px;
  left: 0;
  transition: all 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
a:hover::after, a:focus::after {
  width: 100%;
}
a:focus {
  outline: none;
  border: none;
}

span {
  text-decoration: underline;
  text-decoration-style: wavy;
}

a, span {
  text-underline-offset: 4px;
  text-decoration-color: #A28787;
}

@media screen and (min-width: 1440px) {
  h1, p {
    font-size: 1.4vw;
  }
}
@media screen and (max-width: 1024px) {
  body {
    font-size: 18px;
  }
}
/* Main Styles
========================================================================== */
main {
  background-color: #fff;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

section {
  min-height: 100vh;
  display: flex;
}

section.col-left {
  padding: 40px;
  background-color: #28392C;
  align-items: end;
}
section.col-left img {
  max-width: 70%;
}

section.col-right {
  padding: 40px 60px;
  background-color: #B4BBCD;
  flex-direction: column;
  gap: 40px;
}
section.col-right img {
  max-width: 35%;
}

div.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
div.content p {
  margin-top: 1ch;
}

footer {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
}
footer a {
  text-decoration: none;
}

div.social {
  display: flex;
  gap: 12px;
}

div.content, img {
  opacity: 0;
}

@media screen and (max-width: 1024px) {
  main {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  section.col-right, section.col-left {
    padding: 20px;
  }
  section.col-left {
    min-height: auto;
  }
  section.col-left img {
    max-width: 100%;
  }
  section.col-right {
    grid-row: 1/span 1;
    gap: 20px;
    flex-direction: row;
  }
  section.col-right img {
    max-width: 50%;
    height: 100%;
  }
  footer, div.social {
    flex-direction: column;
    gap: 18px;
  }
}
@media screen and (max-width: 650px) {
  section.col-right {
    flex-direction: column;
  }
  section.col-right img {
    height: auto;
  }
}
@media screen and (prefers-reduced-motion) {
  div.content, img {
    opacity: 1;
  }
}