@import 'components/base';

/* Universal Styles
========================================================================== */
@import url("https://use.typekit.net/pju7ias.css");

$color-primary-dark: #28392C;
$color-primary-light: #B4BBCD;
$color-secondary: #A28787;
$color-text: #000000;

$spacing-default: 20px;
$spacing-text: 12px;

/* Utility Styles
----------------------- */

html {
    scroll-behavior: smooth;
}

img {
    object-fit: cover;
    object-position: center;
    aspect-ratio: 4/5;
}

/* Typography
----------------------- */
body {
    font-size: 20px;
    line-height: 150%;
    font-family: "minion-pro", serif;
    font-weight: 400;
    font-style: normal;
    color: $color-text;
}

footer {
    font-size: 14px;
    font-family: "din-2014", serif;
    line-height: 100%;
}

a {
    color: inherit;
    text-decoration: underline;
    display: inline-block;
    position: relative;
    z-index: 1;
    &::after {
        content: "";
        background: $color-secondary;
        width: 0;
        height: 100%;
        position: absolute;
        z-index: -1;
        bottom: 1px;
        left: 0;
        transition: all .3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }
    &:hover::after,
    &:focus::after {
        width: 100%;
    }
    &:focus {
        outline: none;
        border: none;
    }
}

span {
    text-decoration: underline;
    text-decoration-style: wavy;
}

a, span {
    text-underline-offset: 4px;
    text-decoration-color: $color-secondary;
}

@media screen and (min-width: 1440px) {
    h1, p {
        font-size: 1.4vw;
    }
}

@media screen and (max-width: 1024px) {
    body {
        font-size: 18px;
    }
}

/* Main Styles
========================================================================== */

main {
    background-color: #fff;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

section {
    min-height: 100vh;
    display: flex;
}

section.col-left {
    padding: calc( $spacing-default * 2 );
    background-color: $color-primary-dark;
    align-items: end;
    img {
        max-width: 70%;
    }
}

section.col-right {
    padding: calc( $spacing-default * 2 ) calc( $spacing-default * 3 );
    background-color: $color-primary-light;
    flex-direction: column;
    gap: calc( $spacing-default * 2 );
    img {
        max-width: 35%;
    }
}

div.content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    p {
        margin-top: 1ch;
    }
}

footer {
    margin-top: calc( $spacing-default * 3 );
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;

    a {
        text-decoration: none;
    }
}

div.social {
    display: flex;
    gap: $spacing-text;
}

div.content, img {
    opacity: 0;
}

@media screen and (max-width: 1024px) {
    main {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    section.col-right, section.col-left  {
        padding: $spacing-default;
    }
    section.col-left {
        min-height: auto;
        img {
            max-width: 100%;
        }
    }
    section.col-right {
        grid-row: 1 / span 1;
        gap: $spacing-default;
        flex-direction: row;
        img {
            max-width: 50%;
            height: 100%;
        }
    }
    footer, div.social {
        flex-direction: column;
        gap: calc($spacing-text * 1.5);
    }

}

@media screen and (max-width: 650px) {
    section.col-right {
        flex-direction: column;
        img {
            height: auto;
        }
    }
}

@media screen and (prefers-reduced-motion) {
    div.content, img {
        opacity: 1;
    }
}